const Colors = {
  primary: "#085C54",
  stock: "#B8860B",
  mainLayoutAtiveBackground: "#e6eef6",
  mainLayoutHoverBackground: "#dfeaf6",
  mainLayoutFontColor: "#6a6d71",
  titleWithBackgroudColor: "#356859",
  titleRowWithBackgroundColor: "#C5D7C5",
  defaultPriceTable: "#EEE8AA",
  noResultBackgroud: "#EEE8AA",
  noResultFont: "#B8860B",
  muiPrimary: "#1769aa",
  muiDanger: "#d32f2f",
  iconButtonProductsBackground: "#C6E2FF",
  iconButtonProducts: "#4682B4",
  iconButtonDiscountsBackground: "#FFE8DC",
  iconButtonDiscounts: "#EA9E99",
  iconButtonTotalBackground: "#EAF3E7",
  iconButtonTotal: "#2A8C00",
  mainTextColor: "#4B4B4B",
};

export default Colors;
