import { jsPDF } from "jspdf";
import "jspdf-autotable";

export function detailedPdfSettings(
  formatHeaders,
  fullHeadPDF,
  fullReportPDF,
  columnPDF,
  firstElement,
  lastElement,
  pgTitle,
  currentUser,
  cliente,
  formatIni,
  formatFim,
  ref,
  uf,
  familia,
  produto,
  dt,
  fileTitle,
  totalPDF,
  orientation,
  chartOrd
) {
  const doc = new jsPDF(orientation);
  doc.setFontSize(10);

  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  console.log(columnPDF);
  const tableColumn = formatHeaders;

  if (fullReportPDF) {
    fullReportPDF.forEach((report) => {
      for (let i = 0; i < report.length; i++) {
        if (typeof report[4] === "number") {
          report[4] = currencyFormatter.format(report[4]).slice(3);
          report[5] = currencyFormatter.format(report[5]).slice(3);
          report[6] = currencyFormatter.format(report[6]).slice(3);
        }
      }
    });
  }

  if (totalPDF) {
    totalPDF.forEach((totals, i, array) => {
      console.log(array[3]);
      if (typeof array[3] === "number") {
        array[3] = currencyFormatter.format(array[3]).slice(3);
      }
    });
  }

  console.log(totalPDF);

  let filter1 = "Class";
  let filter2 = "%AV";

  // console.log(totalRows.filter((item) => item.length > 0));
  // console.log(
  //   tableColumn.filter(
  //     (item) => !item.includes(filter1) && !item.includes(filter2)
  //   )
  // );

  let listaCliente = [];
  let listaFamilia = [];
  let listaProduto = [];

  if (cliente)
    cliente.map((cli) => {
      listaCliente.push(" " + cli.label);
    });
  if (familia) listaFamilia.push(" " + familia.toUpperCase());
  if (produto)
    produto.map((prod) => {
      listaProduto.push(" " + prod.label.toUpperCase());
    });

  const addTitle = (doc) => {
    doc.setFontSize(16);
    doc.setTextColor(75, 75, 75);
    doc.text(pgTitle, 14, 12, {
      align: "left",
    });
    doc.setLineWidth(0.2);

    if (orientation === "landscape") {
      doc.line(14, 15, 283, 15);
    } else {
      doc.line(14, 15, 196, 15);
    }
  };

  const addInfo = (doc) => {
    doc.setFontSize(10);
    doc.setTextColor(75, 75, 75);

    if (orientation === "landscape") {
      doc.text(currentUser, 283, 13, {
        align: "right",
      });
    } else {
      doc.text(currentUser, 196, 13, {
        align: "right",
      });
    }
  };

  const addFooters = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);
    doc.setTextColor(75, 75, 75);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      if (orientation === "landscape") {
        doc.text("Página " + String(i) + " de " + String(pageCount), 14, 202, {
          align: "left",
        });
      } else {
        doc.text("Página " + String(i) + " de " + String(pageCount), 14, 289, {
          align: "left",
        });
      }

      if (orientation === "landscape") {
        doc.text(dt.toLocaleString(), 283, 202, {
          align: "right",
        });
      } else {
        doc.text(dt.toLocaleString(), 196, 289, {
          align: "right",
        });
      }
    }
  };

  const addLink = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(6);
    doc.setTextColor(75, 75, 75);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      if (orientation === "landscape") {
        doc.text("www.ivitech.com.br", doc.internal.pageSize.width / 2, 202, {
          align: "center",
        });
      } else {
        doc.text("www.ivitech.com.br", doc.internal.pageSize.width / 2, 288, {
          align: "center",
        });
      }
    }
  };

  addTitle(doc);
  addInfo(doc);

  let cliTable =
    listaCliente.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: 18,
          head: [["CLIENTES:" + [[listaCliente]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  doc.autoTable({
    theme: "plain",
    startY: listaCliente.length > 0 ? doc.lastAutoTable.finalY + 0.5 : 18,
    head:
      uf.length > 0 && ref.length > 0
        ? [
            [
              `PERÍODO: ${formatIni + " - " + formatFim}` +
                "       " +
                `UF: ${[[uf]]}` +
                "       " +
                `REFERÊNCIA: ${[[ref]]}`,
            ],
          ]
        : uf.length > 0 && ref.length <= 0
        ? [
            [
              `PERÍODO: ${formatIni + " - " + formatFim}` +
                "       " +
                `UF: ${[[uf]]}`,
            ],
          ]
        : ref.length > 0 && uf.length <= 0
        ? [
            [
              `PERÍODO: ${formatIni + " - " + formatFim}` +
                "       " +
                `REFERÊNCIA: ${[[ref]]}`,
            ],
          ]
        : [[`PERÍODO: ${formatIni + " - " + formatFim}`]],
    styles: {
      halign: "left",
      cellPadding: 0.8,
      fontSize: 8,
      fontStyle: "normal",
      textColor: "#4b4b4b",
      marginBottom: 1,
    },
  });

  // let ufTable =
  //   uf.length > 0
  //     ? doc.autoTable({
  //         theme: "plain",
  //         startY: doc.lastAutoTable.finalY + 0.5,
  //         head: [["UF:" + [[uf]]]],
  //         styles: {
  //           halign: "left",
  //           cellPadding: 0.8,
  //           fontSize: 8,
  //           fontStyle: "normal",
  //           textColor: "#4b4b4b",
  //           marginBottom: 1,
  //         },
  //       })
  //     : "";

  let familiaTable =
    listaFamilia.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["FAMILIA:" + [[listaFamilia]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  let produtoTable =
    listaProduto.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["PRODUTO:" + [[listaProduto]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  const teste = [
    {
      halign: "left",
    },
    {
      halign: "center",
    },
    {
      halign: "right",
    },
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 3,
    headStyles: { fillColor: [8, 92, 84] },
    head: [fullHeadPDF],
    body: fullReportPDF,
    columnStyles: columnPDF,
    alternateRowStyles: { fillColor: [241, 241, 241] },
  });

  doc.autoTable({
    head: [
      [
        {
          content: "TOTAL GERAL",
          styles: { halign: "center" },
        },
      ],
    ],
    headStyles: { fillColor: [8, 92, 84], minCellHeight: 0 },
    alternateRowStyles: { fillColor: [241, 241, 241] },
    styles: { cellPadding: 0.8 },
  });

  doc.autoTable(
    tableColumn.filter(
      (item) => !item.includes(filter1) && !item.includes(filter2)
    ),
    // [totalRows.filter((item) => item.length > 0)],
    [totalPDF],
    {
      startY: doc.lastAutoTable.finalY + 0,
      headStyles: { fillColor: [8, 92, 84], cellPadding: 0.8 },
      alternateRowStyles: { fillColor: [241, 241, 241] },
      styles: { halign: "center", cellPadding: 0.8 },
    }
  );

  addFooters(doc);
  addLink(doc);
  doc.save(
    `${fileTitle}_${formatIni.replace(/\//g, "-")}_${formatFim.replace(
      /\//g,
      "-"
    )}.pdf`
  );
}
