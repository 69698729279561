import React, { useState, useEffect } from "react";

import axios from "axios";

import { StyleSheet, css } from "aphrodite";
import { Link, useMatch } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";

import Divider from "@mui/material/Divider";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import faturamentoAnual from "../../assets/imgs/faturamento_anual.svg";

import logoIvitech_colorido from "../../assets/imgs/logoivitech_colorido.png";
import ivitech_reduced from "../../assets/imgs/ivitech_reduced.png";

import iconSiga2 from "../../assets/imgs/siga-icos-02.svg";

import "../../assets/styles/drawer.css";

import { headers } from "../core/store/localStorage";
import errorHandler from "../core/store/errorHandler";

// const API_URL = localStorage.getItem("apiUrl");
// const userId = localStorage.getItem("userId");

const asyncLocalStorage = {
  async getItem(key: any) {
    await null;
    return localStorage.getItem(key);
  },
};

interface Props {
  open: boolean;
  openDialog: boolean;
  openQuerySubMenu: boolean;
  toggleDialog: () => void;
  toggleQueryMenu: () => void;
}

const DrawerItemsList: any = (props: Props) => {
  const [pages, setPages] = useState<any>("");
  const [API_URL, setCurrentAPI] = useState<any>("");
  const [userId, setCurrentUserID] = useState<any>("");

  useEffect(() => {
    getAPI();
    getUserID();
  }, []);

  useEffect(() => {
    getUserAccess();
  }, [API_URL, userId]);

  // console.log(API_URL);
  // console.log(userId);

  const { open, openDialog, openQuerySubMenu, toggleDialog, toggleQueryMenu } =
    props;

  const [openVendasSubMenu, setOpenVendasSubMenu] = useState(false);

  function toggleVendasMenu() {
    setOpenVendasSubMenu(!openVendasSubMenu);
  }

  function getAPI() {
    asyncLocalStorage.getItem("apiUrl").then((response) => {
      setCurrentAPI(response);
    });
  }
  function getUserID() {
    asyncLocalStorage.getItem("userId").then((response) => {
      setCurrentUserID(response);
    });
  }

  async function getUserAccess() {
    await axios
      .get(`${API_URL}/user/${userId}/tela`, headers())
      .then((response) => {
        let resp: any = [];
        response.data.data.map((res: any) => {
          resp.push(res.name);
        });
        setPages(resp);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function clearData() {
    localStorage.clear();
  }

  return (
    <>
      <List style={{ marginTop: "0.15rem" }}>
        <Link to={"/curvaABC-produtos"} className={css(styles.noDecoration)}>
          <ListItemButton
            // selected={!!useMatch("/produtos/*")}
            key={"CurvaAVC-produtos"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            {/* <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? "" : -0.2,
                justifyContent: "center",
              }}
            >
              {/* <img style={{ width: "8%" }} src={faturamentoAnual} /> */}
            {/* <EditCalendarIcon />
            </ListItemIcon>  */}

            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? -16.02 : "auto",
                ml: open ? -17.55 : 0,
                justifyContent: "center",
              }}
              title={open ? "" : "Curva ABC - Produtos"}
            >
              <img style={{ width: "8%" }} src={iconSiga2} />
            </ListItemIcon>

            <ListItemText
              primary={"Curva ABC - Produtos"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>

        <Link to={"/faturamento_anual"} className={css(styles.noDecoration)}>
          <ListItemButton
            // selected={!!useMatch("/produtos/*")}
            key={"FaturamentoAnual"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? "" : -0.2,
                justifyContent: "center",
              }}
              title={open ? "" : "Faturamento Anual"}
            >
              {/* <img style={{ width: "8%" }} src={faturamentoAnual} /> */}
              <EditCalendarIcon />
            </ListItemIcon>

            <ListItemText
              primary={"Faturamento Anual"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>

        <Link to={"/faturamento_mensal"} className={css(styles.noDecoration)}>
          <ListItemButton
            // selected={!!useMatch("/produtos/*")}
            key={"FaturamentoMensal"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? "" : -0.2,
                justifyContent: "center",
              }}
              title={open ? "" : "Faturamento Mensal"}
            >
              <QueryStatsOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Faturamento Mensal"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>

        {/* {pages.includes("vendas/referencia") ||
        pages.includes("vendas/curvaABC-referencia") ||
        pages.includes("vendas/referencia") ? (
          <ListItemButton
            onClick={toggleVendasMenu}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                justifyContent: "center",
              }}
            >
              <AttachMoneyOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Vendas"} sx={{ opacity: open ? 1 : 0 }} />
            {openVendasSubMenu ? (
              <ExpandLess style={{ width: 20 }} color="action" />
            ) : (
              <ExpandMore style={{ width: 20 }} color="action" />
            )}
          </ListItemButton>
        ) : (
          ""
        )}

        {openVendasSubMenu && <Divider />}
        <Collapse in={openVendasSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {pages.includes("/vendas/autorizacao-de-vendas") ? (
              <Link
                to={"/vendas/autorizacao-de-vendas"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/autorizacao-de-vendas/*")}
                  key={"autorizaVendas"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <CreditScoreOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Autorização de Vendas"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/curvaABC-clientes") ? (
              <Link
                to={"/vendas/curvaABC-clientes"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/curvaABC-clientes/*")}
                  key={"curvaAVC-clientes"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? -16.02 : "auto",
                      ml: open ? -17.55 : 0,
                      justifyContent: "center",
                    }}
                  >
                    <img style={{ width: "8%" }} src={iconSiga1} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Curva ABC - Clientes"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/curvaABC-referencia") ? (
              <Link
                to={"/vendas/curvaABC-referencia"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/curvaABC-referencia/*")}
                  key={"curvaAVC-referencias"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? -16.02 : "auto",
                      ml: open ? -17.55 : 0,
                      justifyContent: "center",
                    }}
                  >
                    <img style={{ width: "8%" }} src={iconSiga2} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Curva ABC - Referências"}
                    style={{ fontSize: "12 !important", fontFamily: "Poppins" }}
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/equipe") ? (
              <Link to={"/vendas/equipe"} className={css(styles.noDecoration)}>
                <ListItemButton
                  // selected={!!useMatch("/vendas/equipe/*")}
                  key={"equipes"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <Diversity3OutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Equipe"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("/vendas/estabelecimentos") ? (
              <Link
                to={"/vendas/estabelecimentos"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/produtos/*")}
                  key={"Estabelecimentos"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <StoreMallDirectoryOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Estabelecimento"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("/vendas/meiopagamento") ? (
              <Link
                to={"/vendas/meiopagamento"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/produtos/*")}
                  key={"Meio de Pagamento"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <PaymentsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Meio de Pagamento"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/referencia") ? (
              <Link
                to={"/vendas/referencia"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/referencia/*")}
                  key={"referencias"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginLeft: open ? 1.5 : "auto",
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <AssignmentOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Referência"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        <Divider /> */}

        <Divider style={{ marginTop: "0.5rem" }} />
        <Link to={"/"} className={css(styles.noDecoration)}>
          <ListItemButton
            key={"Sair"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={clearData}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? 0.2 : "",
                justifyContent: "center",
              }}
              title={open ? "" : "Sair"}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
      </List>

      <div>
        <Link to={"https://ivitech.com.br"}>
          <img
            src={open ? logoIvitech_colorido : ivitech_reduced}
            style={{
              position: "absolute",
              bottom: 20,
              left: open ? "27%" : "15%",
              width: open ? "6.5rem" : "2.5rem",
            }}
          />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default DrawerItemsList;
