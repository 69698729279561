import React from "react";
import { useEffect } from "react";
import {
  Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";

import MainLayout from "./components/layout/MainLayout";
import LoginPage from "./pages/login/Login";
import MainPage from "./pages/main/mainPage";
import ProdCurvaABC from "./pages/consultas/curvaABC_produtos/prodCurvaABC";
import FaturamentoAnual from "./pages/consultas/anual/FaturamentoAnual";
import FaturamentoMensal from "./pages/consultas/mensal/FaturamentoMensal";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";

function App() {
  const location = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route element={<MainLayout />}>
          <Route path="/main" element={<MainPage />} />
          <Route path="/curvaABC-produtos" element={<ProdCurvaABC />} />
          <Route path="/faturamento_anual" element={<FaturamentoAnual />} />
          <Route path="/faturamento_mensal" element={<FaturamentoMensal />} />
        </Route>
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
