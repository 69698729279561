import { Grid } from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";
import "../../assets/styles/tabela.css";

function DetailedDatagrid(props) {
  const setHeaders = props.head;
  const setRows = props.row;
  const unit = props.unit;

  let formatRows = [];
  let setTotals = [];
  let formatTotals = [];
  setTotals.push(props.totals);

  const numberComparator = (v1, v2) => v1 - v2;

  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  function numberFormat(value) {
    return currencyFormatter.format(value);
  }

  setHeaders.forEach((sr) => {
    if (sr.isNumber) {
      sr["sortComparator"] = numberComparator;
    } else {
      sr["isNumber"] = false;
    }
  });

  if (setRows) {
    setRows.map((sr) => {
      formatRows.push({
        classificacao: sr.classificacao,
        produtoid: sr.produtoid,
        nome: sr.nome,
        quantidade: sr.quantidade,
        valor: numberFormat(sr.valor).slice(3),
        prmedio: numberFormat(sr.prmedio).slice(3),
        percentualav: numberFormat(sr.percentualav).slice(3),
      });
    });
  }

  if (props.totals) {
    setTotals.map((total) => {
      formatTotals.push({
        produtoid: total.produtoid,
        quantidade: total.quantidade,
        valor: numberFormat(total.valor).slice(3),
      });
    });
  }

  function generateRandom() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnFilter
        columns={setHeaders}
        columnHeaderHeight={30}
        getRowId={(row) => generateRandom()}
        rows={setRows ? formatRows : setRows}
        rowHeight={25}
        isRowSelectable={() => false}
        hideFooter
        disableRowSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        loading={props.loading}
        slotProps={{
          noResultsOverlay: {
            style: { color: "#4b4b4b", fontSize: 14 },
          },
          row: {
            style: { color: "#4b4b4b", fontSize: 12 },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even_row" : "odd_row"
        }
      />

      <Grid container wrap="wrap">
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#085C54",
            borderRadius: "3px 3px 0 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 25,
            marginTop: "1rem",
          }}
        >
          <p className={css(styles.head)}>TOTAL GERAL</p>
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        disableColumnFilter
        columns={setHeaders}
        columnHeaderHeight={0}
        getRowId={(row) => generateRandom()}
        rows={props.totals ? formatTotals : setTotals}
        rowHeight={25}
        row
        isRowSelectable={() => false}
        hideFooter
        disableRowSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          row: {
            style: {
              backgroundColor: "#fff",
              color: "#4b4b4b",
              fontSize: 12,
              // fontWeight: "600",
              // fontFamily: "Poppins",
            },
          },
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    color: "#fff",
    fontWeight: 600,
    fontSize: 14,
    // fontFamily: "Poppins",
  },
});

export default DetailedDatagrid;
