import Select from "react-select";

import { Box, Grid, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";

function Filter(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      marginTop: "0.1rem",
    }),
    placeholder: (base) => ({
      ...base,
      // marginTop: -2,
      color: "#cacaca",
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      marginTop: -2,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    menuList: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
  };

  return (
    <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
      <Grid
        container
        wrap="wrap"
        spacing={1.5}
        style={{
          marginBottom: "1rem",
          paddingBottom: "1.5rem",
          borderBottom: "solid 0.5px #eee",
          //   justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Select
            name="clientes"
            placeholder="Cliente"
            isMulti
            options={props.clienteList}
            value={props.cliValue}
            onChange={(ev) => props.cliSet(ev)}
            noOptionsMessage={() => "Sem resultados"}
            styles={{
              container: (base) => ({
                ...base,
                marginTop: "0.5rem",
              }),
              placeholder: (base) => ({
                ...base,
                // marginTop: -2,
                color: "#cacaca",
                // fontFamily: "Poppins",
                fontSize: 12,
              }),
              input: (base) => ({
                ...base,
                marginTop: -2,
                // fontFamily: "Poppins",
                fontSize: 12,
              }),
              menuList: (base) => ({
                ...base,
                // fontFamily: "Poppins",
                fontSize: 12,
              }),
              multiValue: (base) => ({
                ...base,
                // fontFamily: "Poppins",
                fontSize: 12,
              }),
            }}
          ></Select>
        </Grid>
        <Grid item xs={6} sm={1.4} md={1.4}>
          <DatePicker
            label="Início"
            views={["month", "year"]}
            format="MMM/yyyy"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.4rem",
                // fontFamily: "Poppins",
                fontSize: 12,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                marginTop: 0.2,
                // fontFamily: "Poppins",
                fontSize: "12px !important",
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.iniValue}
            onChange={(ev) => props.iniSet(ev)}
          />
        </Grid>
        <Grid item xs={6} sm={1.4} md={1.4}>
          <DatePicker
            label="Fim"
            views={["month", "year"]}
            format="MMM/yyyy"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.4rem",
                // fontFamily: "Poppins",
                fontSize: 12,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                marginTop: 0.2,
                // fontFamily: "Poppins",
                fontSize: "12px !important",
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.fimValue}
            onChange={(ev) => props.fimSet(ev)}
          ></DatePicker>
        </Grid>
        <Grid item xs={12} sm={3.2} md={3.2}>
          <TextField
            name="referencia"
            id="referencia"
            placeholder={"Referência"}
            value={props.refValue}
            onChange={(ev) => props.refSet(ev.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={{
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                height: 38,
                // fontFamily: "Poppins",
                fontSize: 12,
              },
            }}
          ></TextField>
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <TextField
            name="uf"
            id="uf"
            placeholder={"UF"}
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 2 }}
            value={props.ufValue}
            onChange={(ev) => props.ufSet(ev.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={{
              marginTop: "0.1rem",
              "& .MuiInputBase-root": {
                height: 38,
                // fontFamily: "Poppins",
                fontSize: 12,
              },
            }}
          ></TextField>
        </Grid>
        <Grid item xs={10} sm={3.3} md={4}>
          <TextField
            name="familia"
            id="familia"
            title="Use ',' como separador entre cada família"
            placeholder={"Família"}
            value={props.famValue}
            onChange={(ev) => props.famSet(ev.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={{
              marginTop: "0.1rem",
              "& .MuiInputBase-root": {
                height: 38,
                // fontFamily: "Poppins",
                fontSize: 12,
              },
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3.3} md={4}>
          <Select
            name="produtos"
            placeholder="Produtos"
            isMulti
            options={props.prodList}
            value={props.prodValue}
            onChange={(ev) => props.prodSet(ev)}
            styles={customStyles}
            noOptionsMessage={() => "Sem resultados"}
          ></Select>
        </Grid>
        <Grid
          item
          xs={10}
          sm={3.4}
          md={2.5}
          lg={2.5}
          style={{ marginTop: "0.1rem" }}
        >
          <RadioGroup
            row
            name="order-group"
            defaultValue="quantidade"
            value={props.ordValue}
            onChange={props.ordSet}
            style={{
              borderStyle: "solid",
              borderRadius: 5,
              borderColor: "#cccccc",
              borderWidth: 1.5,
              paddingLeft: 15,
            }}
          >
            <FormControlLabel
              value="quantidade"
              control={<Radio />}
              label="Quantidade"
              sx={{
                "& .MuiTypography-root": {
                  color: "#4b4b4b",
                  // fontFamily: "Poppins",
                  fontSize: 12,
                },
                "& .MuiSvgIcon-root": {
                  height: 18,
                  width: 18,
                },
              }}
            />
            <FormControlLabel
              value="valor"
              control={<Radio />}
              label="Valor"
              sx={{
                "& .MuiTypography-root": {
                  color: "#4b4b4b",
                  // fontFamily: "Poppins",
                  fontSize: 12,
                },
                "& .MuiSvgIcon-root": {
                  height: 18,
                  width: 18,
                },
              }}
            />
          </RadioGroup>
        </Grid>

        <Grid
          item
          xs={2}
          sm={1}
          md={0.5}
          lg={0.5}
          style={{ marginTop: "-1.2rem" }}
        >
          <button
            onClick={() => props.search()}
            className={
              props.loading ? css(styles.btnDisabled) : css(styles.btn)
            }
            title="Buscar"
          >
            <SearchOutlinedIcon />
          </button>
        </Grid>

        <Grid
          container
          wrap="wrap"
          style={{
            marginBottom: -10,
            justifyContent: "center",
          }}
        >
          <p className={css(styles.erro)}>{props.erro}</p>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#085C54",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#085C54",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.45rem 0.3rem 0.4rem 0.4rem",
    width: "100%",
    marginTop: "1.33rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#085C54",
      backgroundColor: "transparent !important",
      borderColor: "#085C54 !important",
    },
    ":active": {
      color: "#085C54",
      backgroundColor: "transparent !important",
      borderColor: "#085C54 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.45rem 0.3rem 0.4rem 0.4rem",
    width: "100%",
    marginTop: "1.33rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});

export default Filter;
