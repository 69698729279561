import React, { useState, useEffect, useRef } from "react";
import Filter from "../../../components/filter/Filter";
import PdfModal from "../../../components/modal/pdfModal";

import { detailedPdfSettings } from "../../../components/pdf/detailedPdfSettings";
import { CSVLink } from "react-csv";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";

import { Box, Grid, Pagination, PaginationItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import DetailedDatagrid from "../../../components/datagrid/DetailedDatagrid";
import Chart from "react-google-charts";
import Spinner from "react-bootstrap/Spinner";

import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";

import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import { StyleSheet, css } from "aphrodite";
import Colors from "../../../components/constants/Colors";

import { headers } from "../../../components/core/store/localStorage";
import errorHandler from "../../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");

const currentUser = localStorage.getItem("userName");

function ProdCurvaABC() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");

  function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  const dt = new Date();

  const pgTitle = "Curva ABC - Produtos";
  const fileTitle = "CURVA_ABC_PRODUTOS";

  //   const [pg, setPg] = useState(1);
  let pg = 1;
  const [pagination, setPagination] = useState("");

  const isFirstRender = useRef(true);

  const [head, setHead] = useState("");
  const [rows, setRows] = useState("");
  const [totals, setTotals] = useState("");
  const [chartHead, setChartHead] = useState("");
  const [chartData, setChartData] = useState([""]);

  const [fullHeadPDF, setFullHeadPDF] = useState("");
  const [fullReportPDF, setFullReportPDF] = useState("");
  const [columnPDF, setColumnPDF] = useState("");
  const [totalPDF, setTotalPDF] = useState("");
  const [fullHeadCSV, setFullHeadCSV] = useState("");
  const [fullReportCSV, setFullReportCSV] = useState("");

  const [cliente, setCliente] = useState("");
  const [inicio, setInicio] = useState(startOfMonth(dt));
  const [fim, setFim] = useState(dt);
  const [ref, setRef] = useState("");
  const [uf, setUf] = useState("");
  const [familia, setFamilia] = useState("");
  const [produto, setProduto] = useState("");
  const [orientation, setOrientation] = useState("");
  const [ordValue, setOrdValue] = useState("quantidade");
  const [chartOrd, setChartOrd] = useState("quantidade");
  const [chartType, setChartType] = useState("ColumnChart");

  const [clienteList, setClienteList] = useState([]);
  const [prodList, setProdList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [pdfTrigger, setPdfTrigger] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [erro, setErro] = useState("");

  const formatIni = inicio.toLocaleString().split(",")[0].substr(3, 8);
  const formatFim = fim.toLocaleString().split(",")[0].substr(3, 8);
  const formatHeaders = [];
  let formatReportCSV = [];
  console.log(formatIni);

  if (head) {
    head.forEach((hd) => {
      formatHeaders.push(hd.headerName);
    });
  }

  const data = [];

  const firstElement = formatIni.split("/")[1];
  const lastElement = formatFim.split("/")[1];

  useEffect(() => {
    getClientes();
    getProdutos();

    if (API_URL === null) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (pdfTrigger) {
      detailedPdfSettings(
        formatHeaders,
        fullHeadPDF,
        fullReportPDF,
        columnPDF,
        firstElement,
        lastElement,
        pgTitle,
        currentUser,
        cliente,
        formatIni,
        formatFim,
        ref,
        uf,
        familia,
        produto,
        dt,
        fileTitle,
        totalPDF,
        orientation,
        chartOrd
      );
      setPdfTrigger(false);
    }
  }, [pdfTrigger]);

  useEffect(() => {
    getReportDataPDF();
    getReportDataCSV();
  }, [pg, trigger]);

  useEffect(() => {
    // setPg(page);
    pg = page;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getData();
  }, [page]);

  async function getData() {
    setLoading(true);

    const formatIni = inicio
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newIni = `${formatIni[1]}${formatIni[0]}`;

    const formatFim = fim
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newFim = `${formatFim[1]}${formatFim[0]}`;

    let clienteLink = [];
    if (cliente !== "") {
      cliente.map((cli) => {
        clienteLink.push("'" + cli.value + "'");
      });
    } else {
      clienteLink = "";
    }

    let familiaLink = [];
    if (familia !== "") {
      let famFormat = familia.split(",");
      famFormat.map((fam) => {
        familiaLink.push("'" + fam + "'");
      });
    } else {
      familiaLink = "";
    }

    let prodLink = [];
    if (produto !== "") {
      produto.map((prod) => {
        prodLink.push("'" + prod.value + "'");
      });
    } else {
      prodLink = "";
    }

    await axios
      .get(
        `${API_URL}/venda/abcproduto?page=${pg}&clienteId=${clienteLink}&periodoInicial=${newIni}&periodoFinal=${newFim}&uf=${uf}&produtoId=${ref}&familiaId=${familiaLink}&tipoProduto=${prodLink}&ordenar=${ordValue}`,
        headers()
      )
      .then((response) => {
        setLoading(false);
        setHead(response.data.columns);
        setRows(response.data.data);
        setTotals(response.data.totalizador);
        setPagination(response.data.totalPages);
      })
      .catch((err) => {
        setLoading(false);
        errorHandler(err);
      });
  }

  function getReportDataPDF() {
    const formatIni = inicio
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newIni = `${formatIni[1]}${formatIni[0]}`;

    const formatFim = fim
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newFim = `${formatFim[1]}${formatFim[0]}`;

    let clienteLink = [];
    if (cliente !== "") {
      cliente.map((cli) => {
        clienteLink.push("'" + cli.value + "'");
      });
    } else {
      clienteLink = "";
    }

    let familiaLink = [];
    if (familia !== "") {
      let famFormat = familia.split(",");
      famFormat.map((fam) => {
        familiaLink.push("'" + fam + "'");
      });
    } else {
      familiaLink = "";
    }

    let prodLink = [];
    if (produto !== "") {
      produto.map((prod) => {
        prodLink.push("'" + prod.value + "'");
      });
    } else {
      prodLink = "";
    }

    axios
      .get(
        `${API_URL}/venda/abcproduto/pdf?clienteId=${clienteLink}&periodoInicial=${newIni}&periodoFinal=${newFim}&uf=${uf}&produtoId=${ref}&familiaId=${familiaLink}&tipoProduto=${prodLink}&ordenar=${ordValue}`,
        headers()
      )
      .then((response) => {
        setFullHeadPDF(response.data.headers);
        setFullReportPDF(response.data.data);
        setColumnPDF(response.data.columnStyles);
        setTotalPDF(response.data.totalizador);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function getReportDataCSV() {
    const formatIni = inicio
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newIni = `${formatIni[1]}${formatIni[0]}`;

    const formatFim = fim
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newFim = `${formatFim[1]}${formatFim[0]}`;

    let clienteLink = [];
    if (cliente !== "") {
      cliente.map((cli) => {
        clienteLink.push("'" + cli.value + "'");
      });
    } else {
      clienteLink = "";
    }

    let familiaLink = [];
    if (familia !== "") {
      let famFormat = familia.split(",");
      famFormat.map((fam) => {
        familiaLink.push("'" + fam + "'");
      });
    } else {
      familiaLink = "";
    }

    let prodLink = [];
    if (produto !== "") {
      produto.map((prod) => {
        prodLink.push("'" + prod.value + "'");
      });
    } else {
      prodLink = "";
    }

    axios
      .get(
        `${API_URL}/venda/abcproduto/csv?clienteId=${clienteLink}&periodoInicial=${newIni}&periodoFinal=${newFim}&uf=${uf}&produtoId=${ref}&familiaId=${familiaLink}&tipoProduto=${prodLink}&ordenar=${ordValue}`,
        headers()
      )
      .then((response) => {
        setFullHeadCSV(response.data.headers);
        setFullReportCSV(response.data.data);

        const currencyFormatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        response.data.data.map((report) => {
          if (ordValue === "valor") {
            let newValue = currencyFormatter.format(report.valor);

            formatReportCSV.push({
              ano: report.ano,
              valor: newValue.slice(3),
            });
          } else if (ordValue === "quantidade") {
            formatReportCSV.push({
              ano: report.ano,
              quantidade: report.quantidade,
            });
          }
        });
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function getChartData() {
    const formatIni = inicio
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newIni = `${formatIni[1]}${formatIni[0]}`;

    const formatFim = fim
      .toLocaleString()
      .split(",")[0]
      .substr(3, 8)
      .split("/");
    const newFim = `${formatFim[1]}${formatFim[0]}`;

    let clienteLink = [];
    if (cliente !== "") {
      cliente.map((cli) => {
        clienteLink.push("'" + cli.value + "'");
      });
    } else {
      clienteLink = "";
    }

    let familiaLink = [];
    if (familia !== "") {
      let famFormat = familia.split(",");
      famFormat.map((fam) => {
        familiaLink.push("'" + fam + "'");
      });
    } else {
      familiaLink = "";
    }

    let prodLink = [];
    if (produto !== "") {
      produto.map((prod) => {
        prodLink.push("'" + prod.value + "'");
      });
    } else {
      prodLink = "";
    }

    axios
      .get(
        `${API_URL}/venda/abcproduto/grafico?clienteId=${clienteLink}&periodoInicial=${newIni}&periodoFinal=${newFim}&uf=${uf}&produtoId=${ref}&familiaId=${familiaLink}&tipoProduto=${prodLink}&ordenar=${ordValue}`,
        headers()
      )
      .then((response) => {
        setChartHead(response.data.columns);
        setChartData(response.data.data);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function getClientes() {
    axios
      .get(`${API_URL}/cliente/combo`, headers())
      .then((response) => {
        let resp = [];
        response.data.clientes.map((res) => {
          resp.push({
            value: res.id,
            label: res.nome,
          });
        });
        setClienteList(resp);
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err);
      });
  }

  function getProdutos() {
    axios
      .get(`${API_URL}/tipoproduto/combo`, headers())
      .then((response) => {
        let resp = [];
        response.data.tiposproduto.map((res) => {
          resp.push({
            value: res.nome,
            label: res.nome,
          });
        });
        setProdList(resp);
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err);
      });
  }

  function updateCliente(e) {
    setCliente(e);
  }
  function updateInicio(e) {
    setInicio(e);
    console.log(e.toLocaleString().split(",")[0].substr(3, 8));
  }
  function updateFim(e) {
    setFim(e);
    console.log(e.toLocaleString().split(",")[0].substr(3, 8));
  }
  function updateRef(e) {
    setRef(e);
  }
  function updateUf(e) {
    setUf(e.toUpperCase());
  }
  function updateFamilia(e) {
    setFamilia(e);
  }
  function updateProduto(e) {
    setProduto(e);
  }
  function updateOrder(e) {
    setOrdValue(e.target.value);
  }
  function updateChartType(e) {
    setChartType(e.target.value);
  }

  const searchFaturamentos = () => {
    if (inicio > fim) {
      setErro(
        "A data final deve ser posterior à data de início. Selecione o período novamente."
      );
    } else {
      setErro("");
      setTrigger(true);
      getData();
      getReportDataPDF();
      getReportDataCSV();
      getChartData();
      setChartOrd(ordValue);
    }
  };

  let totalChars = "";
  let headChars = "";

  if (totalPDF) {
    totalChars = totalPDF.join("").length;
  }
  if (formatHeaders) {
    headChars = formatHeaders.join("").length;
  }

  if (chartData) {
    data.push(chartHead);

    chartData.forEach((cd) => {
      for (var i = 0; i < cd.length; i++) {
        if (cd[i] === "") {
          cd[i] = 0;
        }
      }
      data.push(cd);
    });
  }

  const options = {
    legend: { position: "bottom", textStyle: { color: "#4b4b4b", bold: true } },
    fontSize: 12,
    chartArea: { width: "85%", height: "65%", top: "10%" },
    hAxis: {
      format: chartOrd === "valor" ? "R$ #" : "",
      textStyle: {
        color: "#4b4b4b",
      },
    },
    vAxis: {
      format: chartOrd === "valor" ? "R$ #" : "",
      textStyle: {
        color: "#4b4b4b",
      },
    },
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const retrato = () => {
    setOrientation("portrait");
    setPdfTrigger(true);
    setModalOpen(false);
  };
  const paisagem = () => {
    setOrientation("landscape");
    setPdfTrigger(true);
    setModalOpen(false);
  };

  const getPDF = () => {
    setPdfTrigger(true);
  };
  const getPDFLandscape = () => {
    setOrientation("landscape");
    setPdfTrigger(true);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
          >
            <h3 className={css(styles.pageTitle)}>{pgTitle}</h3>
          </Grid>

          <Grid
            item
            xs={6}
            sm={5}
            md={4}
            lg={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "-1.3rem",
            }}
          >
            {head && head.length > 0 ? (
              <>
                <button
                  onClick={() => setDisplay(!display)}
                  disabled={loading ? true : false}
                  className={
                    loading ? css(styles.btnDisabled) : css(styles.btn)
                  }
                  style={{ marginRight: 5, padding: "5px 8px 3px 8px" }}
                  title="Alternar tabela/gráfico"
                >
                  <DvrOutlinedIcon />
                </button>

                <button
                  onClick={
                    totalChars > 85 || headChars > 90
                      ? getPDFLandscape
                      : openModal
                  }
                  disabled={loading ? true : false}
                  className={
                    loading ? css(styles.btnDisabled) : css(styles.btn)
                  }
                  style={{ marginRight: 5, padding: "5px 8px 3px 8px" }}
                  title="Exportar como PDF"
                >
                  <PictureAsPdfIcon />
                </button>

                <CSVLink
                  headers={fullHeadCSV}
                  data={fullReportCSV}
                  separator=";"
                  // enclosingCharacter=""
                  filename={`FATURAMENTO_ANUAL_${formatIni.replace(
                    /\//g,
                    "-"
                  )}_${formatFim.replace(/\//g, "-")}.csv`}
                  disabled={loading ? true : false}
                  className={
                    loading ? css(styles.btnDisabled) : css(styles.btn)
                  }
                  style={{ padding: "4px 8px 2px 8px" }}
                  title="Exportar como CSV"
                >
                  <TableViewIcon />
                </CSVLink>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <hr
          style={{ color: "#eee", marginTop: "-0.9rem", marginBottom: "1rem" }}
        />

        <Filter
          cliValue={cliente}
          cliSet={updateCliente}
          iniValue={inicio}
          iniSet={updateInicio}
          fimValue={fim}
          fimSet={updateFim}
          refValue={ref}
          refSet={updateRef}
          ufValue={uf}
          ufSet={updateUf}
          famValue={familia}
          famSet={updateFamilia}
          prodValue={produto}
          prodSet={updateProduto}
          clienteList={clienteList}
          prodList={prodList}
          ordValue={ordValue}
          ordSet={updateOrder}
          search={searchFaturamentos}
          erro={erro}
          loading={loading}
        />
      </Box>

      {head && head.length > 0 && !loading ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "grid",
            }}
          >
            {!display ? (
              <>
                <DetailedDatagrid
                  head={head}
                  row={rows}
                  totals={totals}
                  unit={ordValue}
                  loading={loading}
                />

                {pagination > 1 && (
                  <Pagination
                    showFirstButton
                    showLastButton
                    page={page}
                    count={pagination}
                    sx={{
                      "& .MuiPaginationItem-root": {
                        "&.Mui-selected": {
                          background: Colors.mainLayoutAtiveBackground,
                          color: "black",
                        },
                      },
                      marginTop: "0.5rem",
                    }}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={`/curvaABC-produtos/?page=${item.page}`}
                        {...item}
                      />
                    )}
                  />
                )}
              </>
            ) : (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl
                    variant="standard"
                    sx={{ minWidth: 150, marginTop: -0.5 }}
                  >
                    <InputLabel
                      id="tipos"
                      sx={{
                        fontSize: 10,
                      }}
                    >
                      Tipo de gráfico
                    </InputLabel>
                    <Select
                      labelId="tipos"
                      placeholder="Tipo de gráfico"
                      name="type"
                      isMulti
                      onChange={(ev) => updateChartType(ev)}
                      sx={{
                        color: "#4b4b4b",
                        fontSize: 12,
                      }}
                      noOptionsMessage={() => "Sem resultados"}
                    >
                      <MenuItem
                        value={"ColumnChart"}
                        sx={{
                          color: "#4b4b4b",
                          fontSize: 12,
                        }}
                      >
                        Gráfico de barras vertical
                      </MenuItem>
                      <MenuItem
                        value={"BarChart"}
                        sx={{
                          color: "#4b4b4b",
                          fontSize: 12,
                        }}
                      >
                        Gráfico de barras horizontal
                      </MenuItem>
                      {/* <MenuItem
                        value={"LineChart"}
                        sx={{
                          color: "#4b4b4b",
                          fontSize: 12,
                        }}
                      >
                        Gráfico de linhas
                      </MenuItem> */}
                      <MenuItem
                        value={"PieChart"}
                        sx={{
                          color: "#4b4b4b",
                          fontSize: 12,
                        }}
                      >
                        Gráfico de setores
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Chart
                  chartType={
                    chartType === "LineChart"
                      ? "LineChart"
                      : chartType === "ColumnChart"
                      ? "ColumnChart"
                      : chartType === "BarChart"
                      ? "BarChart"
                      : "PieChart"
                  }
                  chartLanguage="pt"
                  loader={
                    <div>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  }
                  height={400}
                  data={data}
                  options={options}
                  style={{ paddingBottom: "1rem" }}
                />
              </>
            )}
          </Box>
        </>
      ) : loading ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            // height={{ md: "20%", xs: "50%" }}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"#ECF9F2"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <div
              display={"flex"}
              style={{
                marginTop: "-0.5rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
            <div display={"flex"}>
              <p
                style={{
                  color: "#4b4b4b",
                  fontSize: 14,
                  marginBottom: "-1rem",
                }}
              >
                Buscando informações
              </p>
            </div>
          </Grid>
        </Box>
      ) : !loading && trigger ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      ) : (
        ""
      )}

      <PdfModal
        modalOpen={modalOpen}
        retrato={retrato}
        paisagem={paisagem}
        toggle={closeModal}
      />
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#4b4b4b",
  },

  btn: {
    backgroundColor: "#085C54",
    color: "#fff",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#085C54",
    borderRadius: 4,
    marginTop: "-0.5rem",
    cursor: "pointer",

    // padding: "5px 8px 3px 8px",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#085C54",
      backgroundColor: "transparent !important",
      borderColor: "#085C54 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 4,
    marginTop: "-0.5rem",
  },
});

export default ProdCurvaABC;
